<template>
    <div class="p-grid" v-if="mostrarListagem">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-key"></i> Parâmetros</h5>
                    <btn-refresh @click="atualizar()"></btn-refresh>
                </div>
                <tabela
                    :data="parametros"
                    dataKey="parametroId"
                    :globalFilterFields="['codigo', 'descricao']"
                >
                    <template #botoes>
                        <btn-inserir></btn-inserir>
                    </template>
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <btn-detalhar :parametro="slotProps.data"></btn-detalhar>
                            </template>
                        </Column>
                        <Column field="codigo" header="Código" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.codigo }}
                            </template>
                        </Column>
                        <Column field="descricao" header="Descrição" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.descricao }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
    <router-view></router-view>
</template>

<script>
import ParametrosServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            parametros: null,
        };
    },

    methods: {
        obterParametros() {
            this.$store.dispatch('addRequest');
            ParametrosServices.obterTodos().then(response => {
                if (response && response.success) {
                    this.parametros = response.data;
                } else {
                    this.parametros = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterParametros();
        },
    },

    mounted() {
        this.obterParametros();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Parametros';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Parametros') {
                if (this.$store.getters.atualizar) {
                    this.obterParametros();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
